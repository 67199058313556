import "intersection-observer";
import "global.css";
import "@formatjs/intl-numberformat/polyfill";
import "@formatjs/intl-numberformat/locale-data/da";
import { AppProps } from "next/app";
import { PropsWithChildren } from "react";

import { storeWrapper } from "@chef/state-management";
import { Datadog } from "@chef/feature-datadog";
import { DiscountsProvider } from "@chef/state-management/components";
import { DebugProvider } from "@chef/feature-debug";

import { Notifications } from "@chef/feature-notifications";
import { RecipeModal } from "@chef/feature-recipe";
import { MobileAppProvider } from "@chef/feature-mobile-app";
import { useOutlineListener } from "@chef/hooks";
import PreviewProvider from "@chef/feature-sanity/PreviewProvider";

import { gtFix } from "@chef/utils/patch";
import { TrackingProvider } from "@chef/smart-components";

gtFix();

const Providers = ({ children }: PropsWithChildren) => {
  return (
    <MobileAppProvider>
      <TrackingProvider>
        <DiscountsProvider>
          <Datadog />
          <Notifications position="top-center" />
          <DebugProvider>{children}</DebugProvider>
        </DiscountsProvider>
      </TrackingProvider>
    </MobileAppProvider>
  );
};

function RetnemtApp({ Component, pageProps, router }: AppProps) {
  useOutlineListener();

  if (router.pathname.startsWith("/studio")) {
    return <Component {...pageProps} />;
  }

  return (
    <PreviewProvider draftMode={pageProps.draftMode}>
      <Providers>
        <div className="flex flex-col min-h-screen">
          <RecipeModal />
          <Component {...pageProps} />
        </div>
      </Providers>
    </PreviewProvider>
  );
}

export default storeWrapper.withRedux(RetnemtApp);
